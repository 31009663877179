<template>
  <sign-page
    title-text="员工"
    :request="request"
    table-size="small"
    :column-list="columnList"
    :form-parms-add="formParmsAdd"
    :tableActionsWidth="100"
    :form-parms-update="formParmsUpdate"
    :table-actions-fixed="true"
    @dataUpdate="dataUpdate"
    :need-data-file="true">
  </sign-page>
</template>

<script>
import {
  workerRequest as request
} from '@/api'

export default {
  computed: {
    formParmsAdd: {
      get () {
        let data = [{
          type: 'input',
          label: '姓名',
          key: 'name',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '电话号码',
          key: 'phone'
        }]
        return data
      }
    },
    formParmsUpdate: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          let newItem = {}
          for (let key in item) {
            newItem[key] = item[key]
          }
          data.push(newItem)
        })
        return data
      }
    },
    columnList: {
      get () {
        let data = [{
          title: '姓名',
          sort: true,
          field: 'name'
        },
        {
          title: '电话号码',
          sort: true,
          field: 'phone'
        }]
        return data
      }
    }
  },
  methods: {
    dataUpdate () {
      this.$store.dispatch('loadWorkerList', true)
    }
  },
  data () {
    return {
      request: request
    }
  }
}
</script>
